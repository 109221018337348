import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/blog.css';
import image1 from "../images/specialty.webp";
import image2 from "../images/home.webp";
import image3 from "../images/business.webp";

const blogPosts = [
  {
    id: 1,
    title: 'Understanding Home Insurance',
    date: 'Monday Aug 10, 2024',
    description: 'Explore the basics of home insurance, including what is covered, how to choose a policy, and tips for maximizing your coverage.',
    imageUrl: image2,
  },
  {
    id: 2,
    title: 'Top 5 Tips for Car Insurance Savings',
    date: 'Wednesday Jul 15, 2024',
    description: 'Learn five essential tips for saving money on your car insurance without compromising on coverage. Work with the best!',
    imageUrl: image1,
  },
  {
    id: 3,
    title: 'Business Insurances',
    date: 'Friday Sep 5, 2024',
    description: 'Discover the key aspects of business insurance, what to consider, and how to select the right coverage to protect your business.',
    imageUrl: image3,    
  },
];

const Blog = () => (
  <>
    {/* SEO Optimization with Helmet */}
    <Helmet>
      <title>Our Blog - Patrick Rodriguez Insurance Agency</title>
      <meta 
        name="description" 
        content="Stay informed with the latest insights on insurance coverage, savings tips, and best practices from Patrick Rodriguez Insurance Agency's blog." 
      />
      <meta 
        name="keywords" 
        content="insurance blog, home insurance tips, car insurance savings, business insurance, Patrick Rodriguez Insurance Agency" 
      />
      <meta name="author" content="Patrick Rodriguez Insurance Agency" />
      <link rel="canonical" href="https://www.yourwebsite.com/blog" />
      {/* Open Graph Meta Tags for Social Media */}
      <meta property="og:title" content="Our Blog - Patrick Rodriguez Insurance Agency" />
      <meta 
        property="og:description" 
        content="Explore insightful articles on home, car, and business insurance from the experts at Patrick Rodriguez Insurance Agency." 
      />
      <meta property="og:image" content="https://www.yourwebsite.com/path/to/blog-image.jpg" />
      <meta property="og:url" content="https://www.yourwebsite.com/blog" />
      <meta property="og:type" content="website" />
      {/* Twitter Card Meta Tags for Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Our Blog - Patrick Rodriguez Insurance Agency" />
      <meta 
        name="twitter:description" 
        content="Read the latest articles and tips on insurance from Patrick Rodriguez Insurance Agency." 
      />
      <meta name="twitter:image" content="https://www.yourwebsite.com/path/to/blog-image.jpg" />
    </Helmet>

    <Container fluid className="contact-header text-center">
      <h1 className="display-4">Our Blog</h1>
      <p className="contact-jumbo">Protecting you and your assets, every step of the way</p>
    </Container>
    
    <div className="blog-section-container">
      <Container>
        <Row>
          {blogPosts.map((post) => (
            <Col md={12} lg={4} key={post.id} className="mb-4">
              <div className="blog-card">
                <div className="blog-card-img-holder">
                  <img src={post.imageUrl} alt={post.title} />
                </div>
                <h3 className="blog-card-title">{post.title}</h3>
                <span className="blog-card-time">{post.date}</span>
                <p className="blog-card-description">{post.description}</p>
                <div className="blog-card-options">
                  <span>Read Full Blog</span>
                  <button className="blog-card-btn">Blog</button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  </>
);

export default Blog;
