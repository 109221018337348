import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../styles/ServicesSection.css';
import carImage from '../images/car.png';
import houseImage from '../images/house.webp';
import healthImage from '../images/condo.webp';
import lifeImage from '../images/life.webp';
import businessImage from '../images/business.webp';
import renterImage from '../images/renters.webp';

const Coverages = () => {
    const coverages = [
        { title: 'Auto Insurance', image: carImage, description: 'Provides protection against financial losses from accidents, theft, or damage, offering peace of mind daily.' },
        { title: 'Home Insurance', image: houseImage, description: 'Safeguards your house and personal items from fire, theft, or natural disasters, ensuring secure protection.' },
        { title: 'Condo Insurance', image: healthImage, description: 'Offers specialized coverage for condo owners, protecting personal property, liability, and unit improvements.' },
        { title: 'Life Insurance', image: lifeImage, description: 'Guarantees financial stability for your loved ones after your passing, offering support for future needs ahead.' },
        { title: 'Business Insurance', image: businessImage, description: 'Protects your business from risks like property damage, lawsuits, and employee issues, ensuring continuity.' },
        { title: 'Renters Insurance', image: renterImage, description: 'Covers personal belongings in rented spaces against loss or damage and provides liability protection always.' },
    ];

    return (
        <div className="container coverage-container">
            <header className="coverage-header text-center mb-4">
                <p className="unique-services-section__container-title">Our Services</p>
                <h2 className="unique-services-section__subheader unique-text-test1 serv-section">Discover Our Insurance Solutions</h2>
            </header>
            <div className="row">
                {coverages.map((coverage, index) => (
                    <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
                        <button type="button" className="coverage-card w-100">
                            <img src={coverage.image} alt={`${coverage.title} icon`} className="coverage-card__icon" />
                            <div className="coverage-card__image-overlay"></div>
                            <div className="coverage-card__overlay">
                                <div className="coverage-card__header">
                                    <div className="coverage-card__header-text">
                                        <h3 className="coverage-card__title">{coverage.title}</h3>
                                    </div>
                                </div>
                                <p className="coverage-card__description">{coverage.description}</p>
                            </div>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Coverages;
