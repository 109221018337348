import React from 'react';
import logo1 from '../images/networks/13.png';
import logo2 from '../images/networks/2.png';
import logo3 from '../images/networks/3.png';
import logo4 from '../images/networks/4.png';
import logo5 from '../images/networks/5.png';
import logo6 from '../images/networks/6.png';
import logo7 from '../images/networks/7.png';
import logo8 from '../images/networks/8.png';
import logo9 from '../images/networks/9.png';
import logo10 from '../images/networks/10.png';
import logo11 from '../images/networks/11.png';
import logo12 from '../images/networks/12.png';
import logo13 from '../images/networks/1.png';
import logo14 from '../images/networks/14.png';
import logo15 from '../images/networks/15.png';
import logo16 from '../images/networks/16.png';
import logo17 from '../images/networks/17.png';
import logo18 from '../images/networks/18.png';
import logo19 from '../images/networks/19.png';
import logo20 from '../images/networks/20.png';
import logo21 from '../images/networks/21.png';
import logo22 from '../images/networks/22.png';
import logo23 from '../images/networks/23.png';
import logo24 from '../images/networks/24.png';
import '../styles/networks.css'; // Optional: Create a CSS file for custom styling if needed

const Networks = () => {
  const commercialInsuranceLogos = [
    logo1, logo2, logo3, logo4,
    logo5, logo6, logo7, logo8,
    logo9, logo10, logo11, logo12
  ];

  const personalInsuranceLogos = [
    logo13, logo14, logo15, logo16,
    logo17, logo18, logo19, logo20,
    logo21, logo22, logo23, logo24
  ];

  const renderLogos = (logos) => {
    return logos.map((logo, index) => (
      <div className="logo-item" key={index}>
        <img src={logo} alt={`Logo ${index + 1}`} />
      </div>
    ));
  };

  return (
    <div className="networks-container">
      <section className="commercial-insurance">
      <header className="coverage-header text-center mb-4">
                <p className="unique-services-section__container-title">Our Networks</p>
                <h2 className="unique-services-section__subheader unique-text-test1 serv-section">Discover Our Insurance Solutions</h2>
            </header>       
            <p className="unique-services-section__container-networks">Commercial Insurance Networks</p>

             <div className="logo-grid">
          {renderLogos(commercialInsuranceLogos)}
        </div>
      </section>
      <hr className="divider-networks" />


      <section className="personal-insurance">
      <p className="unique-services-section__container-networks">Personal Insurance Networks</p>
      <div className="logo-grid">
          {renderLogos(personalInsuranceLogos)}
        </div>
      </section>
    </div>
  );
};

export default Networks;
