import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import '../styles/testimonial.css';

const testimonials = [
  {
    id: 1,
    name: 'Lester A.',
    text: 'Want to understand insurance the proper way? This is the man to go to. Got me a great quote and I learned a lot about insurance I really didn\'t know. I highly recommend Patrick Rodriguez Insurance Agency.',
  },
  {
    id: 2,
    name: 'Kirstalyn C.',
    text: 'Thanks for the staff work, they were helpful taking care of your policy, awesome & polite. I never regret be part of this insurance since 2016. Its been 4 years now when I first started and I always feel satisfied.',
  },
  {
    id: 3,
    name: 'Ericka R.',
    text: 'Highly satisfied with Patrick Rodriguez, he is trustworthy and gives the best coverage possible that will benefit you in case of an accident. The best coverage possible for your business and personal life.',
  },
];

const TestimonialsSection = () => {
  return (
    <Container fluid className="testimonials-section py-5">
      <Row className="text-center mb-4">
        <Col xs={12}>
          <h2 className="text-white head-test">What Our Clients Say</h2>
          <p className="subheader text-test">Read some of our client testimonials</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {testimonials.map((testimonial) => (
          <Col key={testimonial.id} xs={12} sm={8} md={6} lg={4} className="mb-4 d-flex justify-content-center">
            <div className="testimonial-card mx-3">
              <p className="testimonial-text">{testimonial.text}</p>
              <h5 className="testimonial-name mt-3">{testimonial.name}</h5>
              <p className="testimonial-title">{testimonial.title}</p>
              <div className="stars">
                {/* FontAwesome Icons for the stars */}
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default TestimonialsSection;
