import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        {/* Top Row: Icons and Text */}
        <Row className="text-center py-3 footer-top">
  <Col className="footer-item foot-item1" xs={12} sm={4}>
    <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
    <p className='footer-info'>37675 Niles Blvd, Fremont, CA, US</p>
  </Col>
  <Col className="footer-item foot-item1" xs={12} sm={4}>
    <FontAwesomeIcon icon={faPhone} size="lg" />
    <p className='footer-info'>(510) 737-0760</p>
  </Col>
  <Col className="footer-item foot-item1" xs={12} sm={4}>
    <FontAwesomeIcon icon={faEnvelope} size="lg" />
    <p className='footer-info'>service@prodriguezagency.com</p>
  </Col>
</Row>

        <hr className="footer-divider" />

        {/* Middle Row: Company Name and Links */}
        <Row className="text-center py-3 footer-middle">
          <Col>
            <h4 className='company-name'>Patrick Rodriguez Insurance Agency </h4>
            <ul className="footer-links list-inline">
              <li className="list-inline-item"><Link to="/">Home</Link></li>
              <li className="list-inline-item"><Link to="/blog">Blog</Link></li>
              <li className="list-inline-item"><Link to="/services">Services</Link></li>
              <li className="list-inline-item"><Link to="/about">About</Link></li>
              <li className="list-inline-item"><Link to="/contact">Contact</Link></li>
              <li className="list-inline-item"><Link to="/quote">Get a Quote</Link></li>
            </ul>
          </Col>
        </Row>
        <hr className="footer-divider" />

        {/* Bottom Row: Copyright */}
        <Row className="text-center py-3">
          <Col>
            <p className='copyright-text'>© 2024 Patrick Rodriguez Insurance Agency. All Rights Reserved. Designed by Divine Software Systems</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
