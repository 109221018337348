import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Container } from 'react-bootstrap';
import QuoteTypeSelection from './QuoteTypeSelection';

export default function Quotes() {
  return (
    <main className="main">
      {/* SEO Optimization with Helmet */}
      <Helmet>
        <title>Get a Quote - Patrick Rodriguez Insurance Agency</title>
        <meta 
          name="description" 
          content="Get a personalized insurance quote from Patrick Rodriguez Insurance Agency. Select the type of insurance you need and receive a customized quote to protect your assets and loved ones." 
        />
        <meta 
          name="keywords" 
          content="insurance quote, get a quote, personalized insurance quote, Patrick Rodriguez Insurance Agency, insurance coverage" 
        />
        <meta name="author" content="Patrick Rodriguez Insurance Agency" />
        <link rel="canonical" href="https://www.yourwebsite.com/quotes" />
        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Get a Quote - Patrick Rodriguez Insurance Agency" />
        <meta 
          property="og:description" 
          content="Get a personalized quote for your insurance needs. Choose the type of insurance required and let Patrick Rodriguez Insurance Agency provide you with the best coverage options." 
        />
        <meta property="og:image" content="https://www.yourwebsite.com/path/to/quote-image.jpg" />
        <meta property="og:url" content="https://www.yourwebsite.com/quotes" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags for Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get a Quote - Patrick Rodriguez Insurance Agency" />
        <meta 
          name="twitter:description" 
          content="Choose your insurance type and get a personalized quote from Patrick Rodriguez Insurance Agency today." 
        />
        <meta name="twitter:image" content="https://www.yourwebsite.com/path/to/quote-image.jpg" />
      </Helmet>

      <Container fluid className="contact-header text-center">
        <h1 className="display-4">Get a Quote</h1>
        <p className="contact-jumbo">Choose insurance needed below!</p>
      </Container>
      <Container fluid className="p-4">
        <QuoteTypeSelection />
      </Container>
    </main>
  );
}
