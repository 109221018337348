import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icon
import '../styles/Services.css';
import businessLinesImage from '../images/1.webp';
import personalLinesImage from '../images/3.webp';
import servicesImage from '../images/service.webp';
import { Link } from 'react-router-dom';

const Services = () => {
  const [showPersonalTabs, setShowPersonalTabs] = useState(false);
  const [showBusinessTabs, setShowBusinessTabs] = useState(false);
  const [personalActiveTab, setPersonalActiveTab] = useState('life');
  const [businessActiveTab, setBusinessActiveTab] = useState('property');

  const handlePersonalCardClick = () => {
    setShowPersonalTabs((prev) => !prev); // Toggle visibility of personal tabs
  };

  const handleBusinessCardClick = () => {
    setShowBusinessTabs((prev) => !prev); // Toggle visibility of business tabs
  };

  return (
    <>
      {/* SEO Optimization with Helmet */}
      <Helmet>
        <title>Our Insurance Services - Patrick Rodriguez Insurance Agency</title>
        <meta
          name="description"
          content="Explore a variety of insurance services offered by Patrick Rodriguez Insurance Agency, including personal and business insurance solutions tailored to protect your life, assets, and business."
        />
        <meta
          name="keywords"
          content="insurance services, personal insurance, business insurance, life insurance, auto insurance, property insurance, Patrick Rodriguez Insurance Agency"
        />
        <meta name="author" content="Patrick Rodriguez Insurance Agency" />
        <link rel="canonical" href="https://www.yourwebsite.com/services" />
        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Our Insurance Services - Patrick Rodriguez Insurance Agency" />
        <meta
          property="og:description"
          content="Discover comprehensive insurance services from Patrick Rodriguez Insurance Agency to protect your personal and business interests."
        />
        <meta property="og:image" content="https://www.yourwebsite.com/path/to/services-image.jpg" />
        <meta property="og:url" content="https://www.yourwebsite.com/services" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags for Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Insurance Services - Patrick Rodriguez Insurance Agency" />
        <meta
          name="twitter:description"
          content="Protect your life, assets, and business with customized insurance solutions from Patrick Rodriguez Insurance Agency."
        />
        <meta name="twitter:image" content="https://www.yourwebsite.com/path/to/services-image.jpg" />
      </Helmet>

      <Container fluid className="contact-header text-center">
        <h1 className="display-4">Our Insurance Services</h1>
        <p className="contact-jumbo">Protecting you and your assets, every step of the way</p>
      </Container>

      <section className="dark">
        <div className="container py-4">
          <Row className="mb-4">
            {/* Personal Lines Card */}
            <Col md={12} lg={6}>
              <motion.div
                className="card custom-card"
                onClick={handlePersonalCardClick}
                style={{ cursor: 'pointer' }} // Add pointer cursor
              >
                <img
                  className="card-img-top small-img"
                  src={personalLinesImage}
                  alt="Personal Lines"
                />
                <div className="card-body text-left">
                  <h5 className="serv-card-title card-title">
                    Personal Insurances
                    <FontAwesomeIcon icon={faChevronCircleDown} className="ms-2" />
                  </h5>
                  <p className="service-descriptions">Click to learn more!</p>
                  <motion.div
                    initial={false}
                    animate={{ height: showPersonalTabs ? 'auto' : 0, opacity: showPersonalTabs ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ overflow: 'hidden' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tabs
                      id="personal-lines-tabs"
                      activeKey={personalActiveTab}
                      onSelect={(k) => setPersonalActiveTab(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="life" title="Life">
                        <p className="service-descriptions-tab">
                          Life insurance secures your family’s financial future in the unfortunate event of your passing, offering peace of mind.
                        </p>
                      </Tab>
                      <Tab eventKey="home" title="Home">
                        <p className="service-descriptions-tab">
                          Home insurance protects your residence and belongings against various risks like theft, damage, and natural disasters.
                        </p>
                      </Tab>
                      <Tab eventKey="auto" title="Auto">
                        <p className="service-descriptions-tab">
                          Auto insurance covers vehicle damages and liabilities, ensuring safety on the road for you and others, every drive.
                        </p>
                      </Tab>
                      <Tab eventKey="umbrella" title="Umbrella">
                        <p className="service-descriptions-tab">
                          Umbrella insurance provides extra liability protection beyond the limits of your standard insurance policies.
                        </p>
                      </Tab>
                      <Tab eventKey="speciality" title="Speciality">
                        <p className="service-descriptions-tab">
                          Specialty insurance is tailored for unique and high-value items, giving you peace of mind beyond standard policies.
                        </p>
                      </Tab>
                    </Tabs>
                  </motion.div>
                </div>
              </motion.div>
            </Col>

            {/* Business Lines Card */}
            <Col md={12} lg={6}>
              <motion.div
                className="card custom-card biz-marg"
                onClick={handleBusinessCardClick}
                style={{ cursor: 'pointer' }} // Add pointer cursor
              >
                <img
                  className="card-img-top small-img"
                  src={businessLinesImage}
                  alt="Business Lines"
                />
                <div className="card-body text-left">
                  <h5 className="serv-card-title card-title">
                    Business Insurances
                    <FontAwesomeIcon icon={faChevronCircleDown} className="ms-2" />
                  </h5>
                  <p className="service-descriptions">Click to learn more!</p>
                  <motion.div
                    initial={false}
                    animate={{ height: showBusinessTabs ? 'auto' : 0, opacity: showBusinessTabs ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ overflow: 'hidden' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tabs
                      id="business-lines-tabs"
                      activeKey={businessActiveTab}
                      onSelect={(k) => setBusinessActiveTab(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="property" title="Property">
                        <p className="service-descriptions-tab">
                          Property insurance protects business assets from risks such as fire, theft, vandalism, and natural disasters.
                        </p>
                      </Tab>
                      <Tab eventKey="business-auto" title="Business Auto">
                        <p className="service-descriptions-tab">
                          Business auto insurance covers vehicles used for business, protecting against accidents, damages, and liabilities.
                        </p>
                      </Tab>
                      <Tab eventKey="restaurant" title="Restaurant">
                        <p className="service-descriptions-tab">
                          Restaurant insurance covers property damage, employee-related incidents, and liability risks specific to dining.
                        </p>
                      </Tab>
                      <Tab eventKey="office-professional" title="Office and Professional Services">
                        <p className="service-descriptions-tab">
                          Insurance for office-based businesses, covering professional liability and property damages, tailored for your needs.
                        </p>
                      </Tab>
                      <Tab eventKey="wholesaler" title="Wholesaler">
                        <p className="service-descriptions-tab">
                          Wholesaler insurance provides protection for inventory, property, and liability, safeguarding your supply chain business.
                        </p>
                      </Tab>
                      <Tab eventKey="hospitality" title="Hospitality">
                        <p className="service-descriptions-tab">
                          Hospitality insurance ensures comprehensive coverage for hotels, motels, and similar businesses from various risks.
                        </p>
                      </Tab>
                      <Tab eventKey="retail" title="Retail Sales/Services">
                        <p className="service-descriptions-tab">
                          Retail insurance protects inventory, liability, and property, securing your retail business from everyday challenges.
                        </p>
                      </Tab>
                      <Tab eventKey="auto-garage-sales" title="Auto Garage/Sales">
                        <p className="service-descriptions-tab">
                          Insurance for auto garages and dealerships, covering liability, property, vehicles, and operational risks safely.
                        </p>
                      </Tab>
                      <Tab eventKey="manufacturing" title="Manufacturing">
                        <p className="service-descriptions-tab">
                          Manufacturing insurance provides protection for equipment, inventory, liability, and other risks unique to production.
                        </p>
                      </Tab>
                      <Tab eventKey="contractors" title="Contractors">
                        <p className="service-descriptions-tab">
                          Contractor insurance offers protection against risks associated with construction work, ensuring a secure operation.
                        </p>
                      </Tab>
                      <Tab eventKey="entertainment" title="Entertainment">
                        <p className="service-descriptions-tab">
                          Entertainment insurance protects against liability and property risks for events, productions, and venues of all types.
                        </p>
                      </Tab>
                      <Tab eventKey="non-profits" title="Non Profits">
                        <p className="service-descriptions-tab">
                          Insurance for non-profits offers liability and property coverage, ensuring the safety of your organization’s mission.
                        </p>
                      </Tab>
                    </Tabs>
                  </motion.div>
                </div>
              </motion.div>
            </Col>
          </Row>

          <article className="postcard light blue">
            <button className="postcard__img_link btn-link">
              <img className="postcard__img small-img" src={servicesImage} alt="Health Insurance" />
            </button>
            <div className="postcard__text t-dark text-left">
              <h1 className="serv-card-title1">Get Insured Today!</h1>
              <div className="postcard__bar"></div>
              <div className="service-descriptions2">
                Insurance offers financial protection, security, and peace of mind by covering risks to your health, assets, and life.
              </div>
              <Link to="/quote">
                <button className="btn-primary btn-service btn-responsive mt-3" style={{ fontWeight: 'bold' }}>Get a Quote</button>
              </Link>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default Services;
