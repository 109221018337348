import React, { useState } from 'react';
import FormStepOne from './FormStepOne';
import FormStepTwo from './FormStepTwo';
import '../styles/QuoteTypeSelection.css';

// Correct image imports
import business1 from '../images/businesslines.webp';
import personal1 from '../images/personallines.webp';
import propertyImage from '../images/property.webp';
import businessAutoImage from '../images/businessauto.webp';
import restaurantImage from '../images/restuarant.webp';
import officeImage from '../images/office.webp';
import wholesalerImage from '../images/wholesaler.webp';
import hospitalityImage from '../images/hospitality.webp';
import retailImage from '../images/retail.webp';
import autoGarageImage from '../images/autogarage.webp';
import manufacturingImage from '../images/manu.webp';
import contractorImage from '../images/contractor.webp';
import entertainmentImage from '../images/entertainment.webp';
import nonProfitImage from '../images/nonprofit.webp';
import lifeImage from '../images/life1.webp';
import homeImage from '../images/home.webp';
import autoImage from '../images/auto.webp';
import umbrellaImage from '../images/umbrella.webp';
import specialtyImage from '../images/specialty.webp';

// Data for business and personal types
const businessTypes = [
  { type: 'property', img: propertyImage },
  { type: 'business-auto', img: businessAutoImage },
  { type: 'restaurant', img: restaurantImage },
  { type: 'office-professional', img: officeImage },
  { type: 'wholesaler', img: wholesalerImage },
  { type: 'hospitality', img: hospitalityImage },
  { type: 'retail-sales', img: retailImage },
  { type: 'auto-garage', img: autoGarageImage },
  { type: 'manufacturing', img: manufacturingImage },
  { type: 'contractors', img: contractorImage },
  { type: 'entertainment', img: entertainmentImage },
  { type: 'non-profits', img: nonProfitImage }
];

const personalTypes = [
  { type: 'life', img: lifeImage },
  { type: 'home', img: homeImage },
  { type: 'auto', img: autoImage },
  { type: 'umbrella', img: umbrellaImage },
  { type: 'speciality', img: specialtyImage }
];

export default function QuoteTypeSelection() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [quoteType, setQuoteType] = useState('');
  const [showFormModal, setShowFormModal] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIsBusiness(category === 'business');
  };

  const handleQuoteTypeChange = (type) => {
    setQuoteType(type);
    setShowFormModal(true);
  };

  const handleModalClose = () => {
    setShowFormModal(false);
    setQuoteType('');
    setSelectedCategory(null);
    setIsBusiness(false);
    setCurrentStep(1);
    setFormData({});
  };

  // Separate function to go back from Step 2 to Step 1
  const handleBackToQuoteTypeSelection = () => {
    setShowFormModal(false); // Close the form modal
  };

  // Separate function to go back from Quote Type Selection to Business/Personal Lines Selection
  const handleBackToCategorySelection = () => {
    setSelectedCategory(null); // Reset the category selection
    setQuoteType(''); // Clear the quote type
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = () => {
    setCurrentStep(2);
  };

  const handleEdit = () => {
    setCurrentStep(1);
  };

  const handleFinalSubmit = () => {
    console.log('Final form data:', formData);
    handleModalClose();
  };

  return (
    <div className="quoteTypeSelection">
      <div className="headerContainer">
        <h1 className='quote-header'>What type of insurance are you looking for?</h1>
        <p className='quote-text unique-services-section__subheader unique-text-test1'>
          To get a quote, click an insurance type below to find your desired form. We will review your info <br />
          and get in touch to discuss the best insurance options for you.
        </p>
      </div>
      {!selectedCategory ? (
        <div className="quoteTypeContainer large-cards">
          <div className="quoteTypeCard large-card card-pers" onClick={() => handleCategoryClick('personal')}>
            <img src={personal1} alt="Personal Lines" className="card-image" />
          </div>
          <div className="quoteTypeCard large-card card-biz" onClick={() => handleCategoryClick('business')}>
            <img src={business1} alt="Business Lines" className="card-image" />
          </div>
        </div>
      ) : (
        <div className="quoteTypeContainer">
          {selectedCategory === 'personal' && personalTypes.map(personalType => (
            <div key={personalType.type} className="quoteTypeCard" onClick={() => handleQuoteTypeChange(personalType.type)}>
              <img src={personalType.img} alt={personalType.type} className="card-image" />
            </div>
          ))}
          {selectedCategory === 'business' && businessTypes.map(businessType => (
            <div key={businessType.type} className="quoteTypeCard" onClick={() => handleQuoteTypeChange(businessType.type)}>
              <img src={businessType.img} alt={businessType.type} className="card-image" />
            </div>
          ))}
        </div>
      )}
      {selectedCategory && !showFormModal && (
        <div className="backButtonContainer">
          <button className="backButton1" onClick={handleBackToCategorySelection}>Back</button>
        </div>
      )}
      
      {/* Conditionally render form steps */}
      {currentStep === 1 && showFormModal && (
        <FormStepOne
          show={showFormModal}
          handleClose={handleModalClose}
          formData={formData}
          formErrors={{}} // Pass empty object if not handling errors
          handleInputChange={handleInputChange}
          handleNext={handleNext}
          quoteType={quoteType}
          isBusiness={isBusiness}
          handleBackToQuoteTypeSelection={handleBackToQuoteTypeSelection} // Correctly pass the function here
        />
      )}
      {currentStep === 2 && showFormModal && (
        <FormStepTwo
          show={showFormModal}
          handleClose={handleModalClose}
          formData={formData}
          onFinalSubmit={handleFinalSubmit}
          handleEdit={handleEdit} // Pass the handleEdit function to FormStepTwo
        />
      )}
    </div>
  );
}
