import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faShieldAlt, faUserShield, faLifeRing } from '@fortawesome/free-solid-svg-icons';
import '../styles/InfoSection.css';

const ServicesSection = () => {
  return (
    <div className="unique-services-section">
      {/* SVG Background */}
      <svg
        className="unique-services-section__svg-background"
        id="visual"
        viewBox="0 0 900 600"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
      >
        <g fill="#001f3f">
          <circle r="400" cx="325" cy="446"></circle>
        </g>
      </svg>

      {/* Features Section */}
      <div className="unique-services-section__container">
        <p className="unique-services-section__container-title">What We Offer</p>
        <h2 className="unique-services-section__subheader unique-text-test1">Discover Our Insurance Solutions</h2>
        <div className="unique-services-section__gradient-cards">
          <div className="unique-services-section__card">
            <div className="unique-services-section__container-card unique-services-section__bg-green-box">
              <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" className="unique-services-section__animated-icon" />
              <p className="unique-services-section__card-title">Affordable Coverage Plans</p>
              <p className="unique-services-section__card-description">Choose from a range of cost-effective insurance plans tailored to protect you and your loved ones.</p>
            </div>
          </div>

          <div className="unique-services-section__card">
            <div className="unique-services-section__container-card unique-services-section__bg-white-box">
              <FontAwesomeIcon icon={faShieldAlt} size="3x" className="unique-services-section__animated-icon" />
              <p className="unique-services-section__card-title">Comprehensive Protection</p>
              <p className="unique-services-section__card-description">Get complete protection for your home, vehicle, health, and life with customizable coverage options.</p>
            </div>
          </div>

          <div className="unique-services-section__card">
            <div className="unique-services-section__container-card unique-services-section__bg-yellow-box">
              <FontAwesomeIcon icon={faUserShield} size="3x" className="unique-services-section__animated-icon" />
              <p className="unique-services-section__card-title">Personalized Service</p>
              <p className="unique-services-section__card-description">Our agents provide dedicated support and guidance to help you choose the right coverage.</p>
            </div>
          </div>

          <div className="unique-services-section__card">
            <div className="unique-services-section__container-card unique-services-section__bg-blue-box">
              <FontAwesomeIcon icon={faLifeRing} size="3x" className="unique-services-section__animated-icon" />
              <p className="unique-services-section__card-title">Emergency Assistance</p>
              <p className="unique-services-section__card-description">24/7 assistance for all emergencies, ensuring you're always prepared for the unexpected.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
