import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Quote from './components/Quoting';
import Blog from './components/blog';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Add the icons to the library
library.add(fas, fab);

// Scroll to top on route change with instant behavior
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' }); // Scroll to top instantly on route change
  }, [pathname]);

  return null;
};

const App = () => (
  <Router>
   
    <ScrollToTop /> {/* Ensure the page always loads at the top */}
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/quote" element={<Quote />} />
      <Route path="/blog" element={<Blog />} />


      {/* Add other routes as needed */}
    </Routes>
    <Footer />
  </Router>
);

export default App;
