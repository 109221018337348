import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useForm, ValidationError } from '@formspree/react';
import '../styles/FormStepOne.css';
import '../styles/FormStepTwo.css';

// Consolidated list of business quote types
const businessQuoteTypes = [
  'property', 'business-auto', 'restaurant', 'office-professional', 'wholesaler',
  'hospitality', 'retail-sales', 'auto-garage', 'manufacturing', 'contractors',
  'entertainment', 'non-profits', 'commercial'
];

export default function FormStepOne({
  show,
  handleClose,
  formData = {},
  formErrors = {},
  handleInputChange,
  quoteType,
  handleBackToQuoteTypeSelection
}) {
  const [step, setStep] = useState(1); // State to track the current form step
  const [progress, setProgress] = useState(0); // State to track progress bar
  const [state, handleSubmit] = useForm('mrbzdlgd'); // Initialize Formspree hook

  // Determine form step content
  const isBusinessType = businessQuoteTypes.includes(quoteType);
  const insuranceLine = isBusinessType ? 'Business Line' : 'Personal Line'; // Set the insurance line

  const isPersonalAuto = quoteType === 'auto';
  const isHomeOrLife = quoteType === 'home' || quoteType === 'life';

  // Define the total number of required fields for Step 1
  const totalFieldsStep1 = 7 + (isPersonalAuto ? 2 : 0) + (isHomeOrLife ? 1 : 0) + (isBusinessType ? 5 : 0);

  // Effect to update progress bar based on filled fields and step
  useEffect(() => {
    if (step === 1) {
      // Calculate the number of filled fields in Step 1
      const filledFields = [
        'firstName', 'lastName', 'phoneNumber', 'address', 'city', 'zip', 'email', 
        ...(isPersonalAuto ? ['license', 'yearMakeModel'] : []),
        ...(isHomeOrLife ? ['dob'] : []),
        ...(isBusinessType ? ['businessName', 'businessTitle', 'entityType', 'businessDescription'] : [])
      ].reduce((count, field) => formData[field] ? count + 1 : count, 0);

      // Calculate progress as a percentage
      const calculatedProgress = (filledFields / totalFieldsStep1) * 100;
      setProgress(calculatedProgress);
    } else if (step === 2) {
      setProgress(100); // Fully fill the progress bar at step 2
    }
  }, [formData, step, totalFieldsStep1, isPersonalAuto, isHomeOrLife, isBusinessType]);

  // Function to handle navigation to the next step
  const handleNext = (event) => {
    event.preventDefault();
    setStep(2); // Move to the next step
    setProgress(100); // Ensure the progress bar is fully filled
  };

  // Function to handle navigation back to the previous step or quote type selection
  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1); // Go back to the previous step
    } else {
      handleBackToQuoteTypeSelection(); // Use the prop function to navigate back to the quote type selection
    }
  };

  // Handle form submission and confirmation
  const handleReviewConfirm = (event) => {
    event.preventDefault();
    handleSubmit(event);
  };

  // Display thank you message upon successful submission
  if (state.succeeded) {
    return (
      <Modal show={show} onHide={handleClose} fullscreen centered>
        <Modal.Header closeButton>
          <Modal.Title className='modal-head'>Step 3: Form Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className='thankyou-head'>Your submission has been received!</h2>
          <p className='thank-you-text'>
            Thank you for your interest in our {quoteType.replace(/-/g, ' ')} insurance! We appreciate your request and will get back to you promptly.
          </p>
          <div className='email-response'>
            <h4 className='response-title'>In the meantime, here's what to expect:</h4>
            <ul className='thank-you-text'>
              <li>Confirmation email has been sent to the provided address.</li>
              <li>Your inquiry is being reviewed by our team of insurance experts.</li>
              <li>We will respond to your message within 24-48 hours.</li>
            </ul>
          </div>
          <Button variant='primary' className='confirm-btn' onClick={handleClose}>
            Close
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleClose} fullscreen centered>
      <Modal.Header closeButton className="custom-modal-close">
        <Modal.Title className="modal-head">
          {step === 1 ? 'Step 1: Enter Your Information' : 'Step 2: Review and Confirm Your Information'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='progressContainer'>
          <div className='progressBar' style={{ width: `${progress}%` }}></div>
        </div>

        {step === 1 ? (
          <form onSubmit={handleNext} className='contactForm'>
            {/* Step 1: User Information Form Fields */}
            {/* Hidden inputs for insurance type and line */}
            <input type="hidden" name="insuranceType" value={quoteType} />
            <input type="hidden" name="insuranceLine" value={insuranceLine} />

            <div className='formRow'>
              <div className='formGroup'>
                <label htmlFor='firstName'>First Name:</label>
                <input
                  type='text'
                  id='firstName'
                  name='firstName'
                  placeholder='John'
                  value={formData.firstName || ''}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.firstName && <span className='errorText'>{formErrors.firstName}</span>}
              </div>
              <div className='formGroup'>
                <label htmlFor='middleName'>Middle Name:</label>
                <input
                  type='text'
                  id='middleName'
                  name='middleName'
                  placeholder='A.'
                  value={formData.middleName || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='lastName'>Last Name:</label>
                <input
                  type='text'
                  id='lastName'
                  name='lastName'
                  placeholder='Doe'
                  value={formData.lastName || ''}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.lastName && <span className='errorText'>{formErrors.lastName}</span>}
              </div>
            </div>

            <div className='formRow'>
              <div className='formGroup'>
                <label htmlFor='phoneNumber'>Phone Number:</label>
                <InputMask
                  mask='(999) 999-9999'
                  id='phoneNumber'
                  name='phoneNumber'
                  placeholder='(123) 456-7890'
                  value={formData.phoneNumber || ''}
                  onChange={handleInputChange}
                  required
                >
                  {(inputProps) => <input {...inputProps} type='tel' />}
                </InputMask>
                {formErrors.phoneNumber && <span className='errorText'>{formErrors.phoneNumber}</span>}
              </div>
              <div className='formGroup'>
                <label htmlFor='address'>Address:</label>
                <input
                  type='text'
                  id='address'
                  name='address'
                  placeholder='123 Main St'
                  value={formData.address || ''}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.address && <span className='errorText'>{formErrors.address}</span>}
              </div>
            </div>

            <div className='formRow'>
              <div className='formGroup'>
                <label htmlFor='floor'>Floor/Apt/Suite Number (optional):</label>
                <input
                  type='text'
                  id='floor'
                  name='floor'
                  placeholder='Apt 4B'
                  value={formData.floor || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='city'>City:</label>
                <input
                  type='text'
                  id='city'
                  name='city'
                  placeholder='New York'
                  value={formData.city || ''}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.city && <span className='errorText'>{formErrors.city}</span>}
              </div>
              <div className='formGroup'>
                <label htmlFor='zip'>ZIP Code:</label>
                <input
                  type='text'
                  id='zip'
                  name='zip'
                  placeholder='10001'
                  value={formData.zip || ''}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.zip && <span className='errorText'>{formErrors.zip}</span>}
              </div>
            </div>

            {/* Conditional Fields for Personal Auto Insurance Type */}
            {isPersonalAuto && (
              <div className='formRow'>
                <div className='formGroup'>
                  <label htmlFor='license'>Driver's License:</label>
                  <input
                    type='text'
                    id='license'
                    name='license'
                    placeholder='D1234567'
                    value={formData.license || ''}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.license && <span className='errorText'>{formErrors.license}</span>}
                </div>
                <div className='formGroup'>
                  <label htmlFor='yearMakeModel'>Year, Make, and Model:</label>
                  <input
                    type='text'
                    id='yearMakeModel'
                    name='yearMakeModel'
                    placeholder='2022 Toyota Camry'
                    value={formData.yearMakeModel || ''}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.yearMakeModel && <span className='errorText'>{formErrors.yearMakeModel}</span>}
                </div>
              </div>
            )}

            {isHomeOrLife && (
              <div className='formGroup'>
                <label htmlFor='dob'>Date of Birth:</label>
                <input
                  type='date'
                  id='dob'
                  name='dob'
                  value={formData.dob || ''}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.dob && <span className='errorText'>{formErrors.dob}</span>}
              </div>
            )}

            {/* Business-specific Fields for business-related quote types */}
            {isBusinessType && (
              <>
                <div className='formRow'>
                  <div className='formGroup'>
                    <label htmlFor='businessName'>Business Name:</label>
                    <input
                      type='text'
                      id='businessName'
                      name='businessName'
                      placeholder='Doe Enterprises'
                      value={formData.businessName || ''}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.businessName && <span className='errorText'>{formErrors.businessName}</span>}
                  </div>
                  <div className='formGroup'>
                    <label htmlFor='businessTitle'>Business Title:</label>
                    <input
                      type='text'
                      id='businessTitle'
                      name='businessTitle'
                      placeholder='CEO'
                      value={formData.businessTitle || ''}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.businessTitle && <span className='errorText'>{formErrors.businessTitle}</span>}
                  </div>
                </div>
                <div className='formRow'>
                  <div className='formGroup'>
                    <label htmlFor='entityType'>Entity Type:</label>
                    <select
                      id='entityType'
                      name='entityType'
                      value={formData.entityType || ''}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=''>Please Select</option>
                      <option value='LLC'>LLC</option>
                      <option value='Corporation'>Corporation</option>
                      <option value='Partnership'>Partnership</option>
                      <option value='Sole Proprietor'>Sole Proprietor</option>
                    </select>
                  </div>
                  <div className='formGroup'>
                    <label htmlFor='businessDescription'>Business Description:</label>
                    <textarea
                      id='businessDescription'
                      name='businessDescription'
                      placeholder='Describe what the business does...'
                      value={formData.businessDescription || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </>
            )}

            {/* Common Email and Message Fields */}
            <div className='formGroup'>
              <label className='email-text' htmlFor='email'>Email Address:</label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder='john.doe@example.com'
                value={formData.email || ''}
                onChange={handleInputChange}
                required
              />
              {formErrors.email && <span className='errorText'>{formErrors.email}</span>}
            </div>
            <div className='formGroup'>
              <label htmlFor='message' className='formMessage'>Message:</label>
              <textarea
                id='message'
                name='message'
                className='formMessage1'
                value={formData.message || ''}
                onChange={handleInputChange}
                required
              />
              {formErrors.message && <span className='errorText'>{formErrors.message}</span>}
            </div>

            {/* Action Buttons for Step 1 */}
            <div className='buttonGroup'>
              <Button variant='secondary' className='back-modal' onClick={handleBack}>Back</Button>
              <Button variant='primary' className='continue-button' type='submit'>Continue</Button>
            </div>

          </form>
        ) : (
          <form onSubmit={handleReviewConfirm}>
            {/* Step 2: Review and Confirm Information */}
            <h2 className='doublecheck-head'>Please review your information below:</h2>
            <div className='formSummary'>
              {Object.entries(formData).map(([key, value]) =>
                value && (
                  <p key={key}>
                    <strong>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</strong> {value}
                  </p>
                )
              )}
              {/* Display selected insurance type and line */}
              <p><strong>Insurance Type:</strong> {quoteType.replace(/-/g, ' ')}</p>
              <p><strong>Insurance Line:</strong> {insuranceLine}</p>
            </div>
            {/* Hidden inputs for insurance type and line */}
            <input type="hidden" name="insuranceType" value={quoteType} />
            <input type="hidden" name="insuranceLine" value={insuranceLine} />
            {/* Dynamically generate hidden inputs */}
            {Object.entries(formData).map(([key, value]) =>
              value && <input key={key} type='hidden' name={key} value={value} />
            )}
            {/* Action Buttons for Step 2 */}
            <div className='buttonGroup'>
              <Button variant='secondary' className='back-modal' onClick={handleBack}>Edit</Button>
              <Button variant='primary' className='continue-button' type='submit' disabled={state.submitting}>Submit</Button>
            </div>
            <ValidationError prefix='Email' field='email' errors={state.errors} />
            <ValidationError prefix='Message' field='message' errors={state.errors} />
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
}
