import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/About.css';
import '../styles/Services.css';
import handsImg from '../images/hands.webp';
import heartsImg from '../images/image2.webp';

const About = () => (
  <div className="contact-container">
    {/* SEO Optimization with Helmet */}
    <Helmet>
      <title>About Us - Patrick Rodriguez Insurance Agency</title>
      <meta 
        name="description" 
        content="Learn about Patrick Rodriguez Insurance Agency, your trusted partner in protecting what matters most. We provide personalized insurance solutions for your home, vehicle, health, and business." 
      />
      <meta 
        name="keywords" 
        content="insurance agency, personal insurance, business insurance, home insurance, auto insurance, health insurance, Patrick Rodriguez Insurance Agency" 
      />
      <meta name="author" content="Patrick Rodriguez Insurance Agency" />
      <link rel="canonical" href="https://www.yourwebsite.com/about" />
      {/* Open Graph Meta Tags for Social Media */}
      <meta property="og:title" content="About Us - Patrick Rodriguez Insurance Agency" />
      <meta 
        property="og:description" 
        content="Meet the team at Patrick Rodriguez Insurance Agency and discover our commitment to providing you with tailored insurance solutions to protect your life and assets." 
      />
      <meta property="og:image" content="https://www.yourwebsite.com/path/to/about-image.jpg" />
      <meta property="og:url" content="https://www.yourwebsite.com/about" />
      <meta property="og:type" content="website" />
      {/* Twitter Card Meta Tags for Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="About Us - Patrick Rodriguez Insurance Agency" />
      <meta 
        name="twitter:description" 
        content="Learn more about our dedicated team and why Patrick Rodriguez Insurance Agency is your trusted partner for personalized insurance services." 
      />
      <meta name="twitter:image" content="https://www.yourwebsite.com/path/to/about-image.jpg" />
    </Helmet>
    
    <Container fluid className="contact-header text-center">
      <h1 className="display-4">About Us</h1>
      <p className="contact-jumbo">Protecting you and your assets, every step of the way</p>
    </Container>

    <Container className="services-content">
      <Row className="align-items-center mb-4 serv-marg">
        <Col md={12} lg={6} className="order-lg-1 order-md-2 img-card">
          <img src={handsImg} alt="Service 1" className="img-fluid serv-img" />
        </Col>
        <Col md={12} lg={6} className="order-lg-2 order-md-1">
          <Card className="service-item">
            <Card.Body>
              <FontAwesomeIcon icon={faUserShield} className="service-icon" />
              <Card.Title className="serv-head">Who We Are</Card.Title>
              <Card.Text className="serv-body">
                We are a dedicated team of insurance professionals committed to providing customized insurance solutions that meet your unique needs. Whether it’s safeguarding your home, vehicle, or personal health, we strive to offer peace of mind through comprehensive coverage and personalized service.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="align-items-center mb-4">
        <Col md={12} lg={6} className="order-lg-2 order-md-2 img-card">
          <img src={heartsImg} alt="Service 2" className="img-fluid serv-img" />
        </Col>
        <Col md={12} lg={6} className="order-lg-1 order-md-1">
          <Card className="service-item">
            <Card.Body>
              <FontAwesomeIcon icon={faShieldAlt} className="service-icon" />
              <Card.Title className="serv-head">Why Choose Us</Card.Title>
              <Card.Text className="serv-body">
                Choosing us means choosing a partner who values transparency, reliability, and tailored service. Our agents work closely with you to understand your needs and provide solutions that are not only effective but also affordable. We believe in building lasting relationships with our clients, providing ongoing support and guidance to help you make informed decisions about your insurance needs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

   
  </div>
);

export default About;
