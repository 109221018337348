import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, InputGroup, Dropdown, Form } from 'react-bootstrap';
import '../styles/Home.css';
import backgroundImage from '../images/pat.webp';
import InfoSection from './InfoSection';
import ServicesSection from './ServicesSection';
import ContactForm from './ContactForm';
import TestimonialsSection from './testimonials';
import FormStepOne from './FormStepOne';
import Networks from './networks';

const Home = () => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(false);

  const personalLines = [
    'Life Insurance', 'Home Insurance', 'Auto Insurance', 'Umbrella Insurance', 'Specialty Insurance'
  ];

  const businessLines = [
    'Property Insurance', 'Business Auto Insurance', 'Restaurant Insurance', 'Office Professional Insurance',
    'Wholesaler Insurance', 'Hospitality Insurance', 'Retail Sales Insurance', 'Auto Garage Insurance',
    'Manufacturing Insurance', 'Contractors Insurance', 'Entertainment Insurance', 'Non-Profits Insurance'
  ];

  const insuranceTypeMapping = {
    'Life Insurance': 'life',
    'Home Insurance': 'home',
    'Auto Insurance': 'auto',
    'Umbrella Insurance': 'umbrella',
    'Specialty Insurance': 'speciality',
    'Property Insurance': 'property',
    'Business Auto Insurance': 'business-auto',
    'Restaurant Insurance': 'restaurant',
    'Office Professional Insurance': 'office-professional',
    'Wholesaler Insurance': 'wholesaler',
    'Hospitality Insurance': 'hospitality',
    'Retail Sales Insurance': 'retail-sales',
    'Auto Garage Insurance': 'auto-garage',
    'Manufacturing Insurance': 'manufacturing',
    'Contractors Insurance': 'contractors',
    'Entertainment Insurance': 'entertainment',
    'Non-Profits Insurance': 'non-profits'
  };

  const handleGetQuote = () => {
    if (selectedInsurance) {
      setShowFormModal(true);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSelect = (insuranceType) => {
    setSelectedInsurance(insuranceType);
    setError(false);
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
  };

  return (
    <div className="home">
      {/* SEO Optimization with Helmet */}
      <Helmet>
        <title>Patrick Rodriguez Insurance Agency - Trusted Insurance Solutions</title>
      </Helmet>

      <div className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="hero-content-home text-left">
          <h1>Patrick Rodriguez <br /> Insurance Agency</h1>
          <p>Select an insurance agent who truly understands and <br />values your unique needs and daily life.</p>
          <div className="hero-buttons d-flex align-items-center">
            <InputGroup className="custom-select-dropdown">
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                  variant={error ? "danger" : "outline-primary"}
                  id="dropdown-basic"
                  className="dropdown-toggle-custom"
                >
                  {selectedInsurance || 'Select Insurance Type'}
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-custom">
                  <Dropdown.Header>Personal Lines</Dropdown.Header>
                  {personalLines.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  <Dropdown.Header>Business Lines</Dropdown.Header>
                  {businessLines.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Button variant="primary" onClick={handleGetQuote} className="ml-2 button-bold">
                Get a Quote
              </Button>
            </InputGroup>
            {error && <Form.Text className="text-danger">Please select an insurance type.</Form.Text>}
          </div>
        </div>
      </div>

      <div className="main-content">
        <InfoSection />
        <TestimonialsSection />
        <div>
          <ServicesSection />
        </div>
        <ContactForm />
        <Networks />

      </div>

      {/* Modal for the existing form */}
      <FormStepOne
        show={showFormModal}
        handleClose={handleCloseModal}
        formData={formData}
        handleInputChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        quoteType={insuranceTypeMapping[selectedInsurance]} // Pass the correct mapped type
        handleBackToQuoteTypeSelection={() => setShowFormModal(false)}
      />
    </div>
  );
};

export default Home;
