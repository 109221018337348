import React from 'react';
import '../styles/ContactForm.css'; // Ensure your CSS file is included
import img1 from '../images/post1.jpg';
import img2 from '../images/post2.jpg';
import img3 from '../images/post3.jpg';

const HomeSocials = () => {
  const socials = [
    {
      name: 'Facebook',
      image: img1,
      link: 'https://www.facebook.com/PatrickRodriguezFarmersAgency/',
      tagColor: 'hs-tag-pink',
      title: 'Learn More About Home Insurance'
    },
    {
      name: 'Facebook',
      image: img2,
      link: 'https://www.facebook.com/PatrickRodriguezFarmersAgency/',
      tagColor: 'hs-tag-blue',
      title: 'Explore Our Car Insurance Options'
    },
    {
      name: 'Facebook',
      image: img3,
      link: 'https://www.facebook.com/PatrickRodriguezFarmersAgency/',
      tagColor: 'hs-tag-black',
      title: 'Debunking Common Insurance Myths'
    }
  ];

  // Function to handle card click
  const handleCardClick = (url) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  return (
    <section className="hs-section">
      <h1 className="text-white head-test socials-head">Connect with Us</h1>
      <p className="subheader text-test socials-marg">Follow us on social media for the latest updates and news</p>
      <div className="hs-container">
        {socials.map((social, index) => (
          <div
            className="hs-card"
            key={index}
            onClick={() => handleCardClick(social.link)}
            style={{ cursor: 'pointer' }}
          >
            <div className="hs-card__header">
              <img src={social.image} alt={`${social.name} logo`} className="hs-card__image" />
            </div>
            <div className="hs-card__body">
              <span className={`hs-tag ${social.tagColor}`}>{social.name}</span>
              <h4 className='social-titles'>{social.title}</h4>
            </div>
            <div className="hs-card__footer">
              <div className="hs-user">
                <div className="hs-user__info">
                  <p>Visit us for more updates</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HomeSocials;
