import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Container, Row, Col } from 'react-bootstrap';
import ContactPage from './ContactPage';
import '../styles/Contact.css';

const Contact = () => (
  <div className="contact-container">
    {/* SEO Optimization with Helmet */}
    <Helmet>
      <title>Contact Us - Patrick Rodriguez Insurance Agency</title>
      <meta 
        name="description" 
        content="Get in touch with Patrick Rodriguez Insurance Agency for all your insurance needs. Contact us today to find the best insurance solutions to protect your assets and loved ones." 
      />
      <meta 
        name="keywords" 
        content="contact Patrick Rodriguez Insurance, insurance inquiries, contact insurance agency, insurance solutions, Patrick Rodriguez Insurance Agency" 
      />
      <meta name="author" content="Patrick Rodriguez Insurance Agency" />
      <link rel="canonical" href="https://www.yourwebsite.com/contact" />
      {/* Open Graph Meta Tags for Social Media */}
      <meta property="og:title" content="Contact Us - Patrick Rodriguez Insurance Agency" />
      <meta 
        property="og:description" 
        content="Reach out to Patrick Rodriguez Insurance Agency to discuss your insurance needs and receive personalized service and solutions." 
      />
      <meta property="og:image" content="https://www.yourwebsite.com/path/to/contact-image.jpg" />
      <meta property="og:url" content="https://www.yourwebsite.com/contact" />
      <meta property="og:type" content="website" />
      {/* Twitter Card Meta Tags for Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Contact Us - Patrick Rodriguez Insurance Agency" />
      <meta 
        name="twitter:description" 
        content="Contact Patrick Rodriguez Insurance Agency today to find the best insurance solutions for protecting your life, health, and assets." 
      />
      <meta name="twitter:image" content="https://www.yourwebsite.com/path/to/contact-image.jpg" />
    </Helmet>

    <Container fluid className="contact-header text-center">
      <h1 className="display-4">Contact Us</h1>
      <p className="contact-jumbo">Protecting you and your assets, every step of the way</p>
    </Container>

    <Container id="contact-form" className="my-5">
      <Row className='contact-page1'>
        <Col md={12} lg={6} className="contact-form-wrapper">
          <h2 className="form-title">Get in Touch</h2>
          <ContactPage />
        </Col>
        <Col md={12} lg={6} className="contact-map">
          <div className="map-container">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed/v1/place?q=37675+Niles+Blvd,+Fremont,+CA+94536&key=AIzaSyD6wSGBrGf_lNK_EdMz-U131TXRIpaVxjs"
              allowFullScreen=""
              loading="lazy"
              onLoad={(e) => e.target.setAttribute('data-loaded', 'true')}
            ></iframe>

            <div className="map-overlay"></div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Contact;
